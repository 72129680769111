// src/components/Home.js
import React from 'react';
import GuildTabs from '../components/GuildTabs';

const Guild = () => (
	<div className="mx-auto w-full animate-fadeIn md:mt-[90px]">
		<h1 className="hidden">GooPay - Giải pháp thanh toán toàn diện</h1>
		<GuildTabs/>
	</div>
);

export default Guild;
