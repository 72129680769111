export const PATH = {
    HOME: '/',
    SERVICES: '/dich-vu',
    ABOUT: '/ve-chung-toi',
    HIRING: '/tuyen-dung',
    CONTACT: '/lien-he',
    GUILD: '/huong-dan-su-dung',
    FAQ: '/cau-hoi-thuong-gap',
    FAQ_INFO: '/cau-hoi-thuong-gap/thong-tin-co-ban',
    FAQ_ACCOUNT: '/cau-hoi-thuong-gap/quan-ly-ung-dung-tk',
    FAQ_TOP_UP: '/cau-hoi-thuong-gap/nap-rut-tien',
    FAQ_TRANSFER: '/cau-hoi-thuong-gap/chuyen-nhan-tien',
    FAQ_INCIDENT: '/cau-hoi-thuong-gap/su-co-va-khieu-nai',
    FAQ_SAFETY_SECURE: '/cau-hoi-thuong-gap/an-toan-va-bao-mat',
    POLICY_USAGE: '/chinh-sach-su-dung',
    POLICY_FUTA_BUSLINE: '/dieu-khoan-chinh-sach-futa-busline',
    POLICY_PRIVACY: '/chinh-sach-quyen-rieng-tu',
    LINK_GUIDE: '/huong-dan-lien-ket/shb',
    DOWNLOAD: '/tai-ve',
}

export const DOWNLOAD_APP_LINK = {
    ONE_LINK: 'https://onelink.to/scmrcc',
    IOS: 'https://apps.apple.com/sg/app/goopay-ewallet/id6444816745',
    ANDROID: 'https://play.google.com/store/apps/details?id=vn.goopay.ewallet&hl=en',
}

export const FAQ_ITEMS = [
    {id: 'FAQ_INFO', title: 'Thông tin cơ bản', path: PATH.FAQ_INFO},
    {id: 'FAQ_ACCOUNT', title: 'Quản lý ứng dụng và tài khoản', path: PATH.FAQ_ACCOUNT},
    {id: 'FAQ_TOP_UP', title: 'Nạp - rút tiền', path: PATH.FAQ_TOP_UP},
    {id: 'FAQ_TRANSFER', title: 'Chuyển- nhận tiền', path: PATH.FAQ_TRANSFER},
    {id: 'FAQ_INCIDENT', title: 'Sự cố và khiếu nại', path: PATH.FAQ_INCIDENT},
    {id: 'FAQ_SAFETY_SECURE', title: 'An toàn và bảo mật', path: PATH.FAQ_SAFETY_SECURE},
]


export const MENU_ITEM_ID = {
    HOME: 'HOME',
    SERVICES: 'SERVICES',
    ABOUT: 'ABOUT',
    HIRING: 'HIRING',
    CONTACT: 'CONTACT',
}


export const MENU_ITEMS = [
    {id: MENU_ITEM_ID.HOME, label: 'menu_home', path: PATH.HOME},
    {id: MENU_ITEM_ID.SERVICES, label: 'menu_services', path: PATH.SERVICES},
    {id: MENU_ITEM_ID.ABOUT, label: 'menu_about', path: PATH.ABOUT},
    {id: MENU_ITEM_ID.HIRING, label: 'menu_hiring', path: PATH.HIRING},
    {id: MENU_ITEM_ID.CONTACT, label: 'menu_contact', path: PATH.CONTACT},
]

export const VARIANT = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
    OUTLINE: 'outline',
    GRADIENT: 'gradient',
    CUSTOM: 'custom',
    ACTIVE: 'active',
}

export const STORE = {
    APPS_STORE: 'https://apps.apple.com/sg/app/goopay-ewallet/id6444816745',
    PLAY_STORE: 'https://play.google.com/store/apps/details?id=vn.goopay.ewallet&hl=en'
}

export const CONTACT_SHEET = {
    API_URL: 'https://api.sheetbest.com/sheets/71230a7e-1ebb-4474-b46e-ecb24639c186'
}

export const tabDataLinked = (index) => ([
    {
        src: `/img/guild-step/linked-step/tab${index}-step1.png`,
        alt: `tab${index}-step${index}`
    },
    {
        src: `/img/guild-step/linked-step/tab${index}-step2.png`,
        alt: `tab${index}-step${index + 1}`
    },
    {
        src: `/img/guild-step/linked-step/tab${index}-step3.png`,
        alt: `tab${index}-step${index + 2}`
    },
    {
        src: `/img/guild-step/linked-step/tab${index}-step4.png`,
        alt: `tab${index}-step${index + 3}`
    },
    {
        src: `/img/guild-step/linked-step/tab${index}-step5.png`,
        alt: `tab${index}-step${index + 4}`
    },
    {
        src: `/img/guild-step/linked-step/tab${index}-step6.png`,
        alt: `tab${index}-step${index + 5}`
    },
    {
        src: `/img/guild-step/linked-step/tab${index}-step7.png`,
        alt: `tab${index}-step${index + 6}`
    },
    {
        src: `/img/guild-step/linked-step/tab${index}-step8.png`,
        alt: `tab${index}-step${index + 7}`
    },
    {
        src: `/img/guild-step/linked-step/tab${index}-step9.png`,
        alt: `tab${index}-step${index + 8}`
    },
    {
        src: `/img/guild-step/linked-step/tab${index}-step10.png`,
        alt: `tab${index}-step${index + 9}`
    },
    {
        src: `/img/guild-step/linked-step/tab${index}-step11.png`,
        alt: `tab${index}-step${index + 10}`
    }
])
