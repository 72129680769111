// src/components/Home.js
import React from 'react';
import FAQBanner from '../../components/Banner/FAQBanner';
import {PATH} from "../../constants";
import NewLink from "../../components/NewLink/NewLink";

const FAQIncident = () => (
	<div className="mx-auto w-full animate-fadeIn text-sm">
		<FAQBanner title='Sự cố và khiếu nại'/>
		<div className='flex px-8 md:px-[256px] pt-8 md:pb-8 justify-center items-center'>
			<NewLink to={PATH.FAQ} className='hidden md:block'>
				<img className='w-[20px]' src="/img/icon/back.png" alt="back"/>
			</NewLink>
			<h2 className='text-center w-full font-bold text-xl uppercase text-primary'>Sự cố và khiếu nại</h2>
		</div>
		<div className='px-8 md:px-[256px] py-8'
		     dangerouslySetInnerHTML={{
			     __html:
				     '<h2 style="font-size: 18px; font-weight: bold; color: #338FE3;">Sự cố khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng</h2>\n' +
				     '<br/>' +
				     '<p><strong>1. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng SCB</strong></p>\n' +
				     '<p>Vấn đề 1: Trang web li&ecirc;n kết ng&acirc;n h&agrave;ng kh&ocirc;ng hiển thị hoặc hiển thị trang trắng</p>\n' +
				     '<p>Hướng dẫn: Kiểm tra lại chất lượng kết nối mạng v&agrave; thử lại.</p>\n' +
				     '<p>Vấn đề 2: Th&ocirc;ng tin kh&ocirc;ng ch&iacute;nh x&aacute;c hoặc kh&ocirc;ng nhớ th&ocirc;ng tin</p>\n' +
				     '<p>Hướng dẫn: Li&ecirc;n hệ hotline ng&acirc;n h&agrave;ng SCB để kiểm tra lại th&ocirc;ng tin t&agrave;i khoản v&agrave; thực hiện đăng nhập lại.</p>\n' +
				     '<p><strong>2. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng Sacombank</strong></p>\n' +
				     '<p>Vấn đề 1: Kh&ocirc;ng nhận được m&atilde; x&aacute;c thực OTP khi li&ecirc;n kết</p>\n' +
				     '<p>Hướng dẫn: Kiểm tra lại số điện thoại bạn đ&atilde; đăng k&yacute; mở t&agrave;i khoản Sacombank v&igrave; m&atilde; x&aacute;c thực OTP sẽ được gửi v&agrave;o ch&iacute;nh số điện thoại đ&oacute;.</p>\n' +
				     '<p>Vấn đề 2: Lỗi ""Th&ocirc;ng tin thẻ kh&ocirc;ng ch&iacute;nh x&aacute;c""</p>\n' +
				     '<p>Hướng dẫn: Li&ecirc;n hệ hotline ng&acirc;n h&agrave;ng Sacombank 1900 5555 88 để kiểm tra lại th&ocirc;ng tin ch&iacute;nh x&aacute;c v&agrave; thực hiện lai.</p>\n' +
				     '<p>Vấn đề 3: Gửi tin nhắn k&iacute;ch hoạt dịch vụ E-com nhưng tin nhắn b&aacute;o lỗi ""Số thẻ bị tr&ugrave;ng""</p>\n' +
				     '<p>Hướng dẫn: Sử dụng số điện thoại bạn đ&atilde; d&ugrave;ng để đăng k&yacute; mở t&agrave;i khoản tại ng&acirc;n h&agrave;ng Sacombank để gửi tin nhắn v&agrave; thử lại.</p>\n' +
				     '<p><strong>3. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng Shinhan Bank</strong></p>\n' +
				     '<p>Vấn đề 1: Th&ocirc;ng tin kh&ocirc;ng ch&iacute;nh x&aacute;c hoặc kh&ocirc;ng nhớ th&ocirc;ng tin</p>\n' +
				     '<p>Hướng dẫn: Li&ecirc;n hệ hotline ng&acirc;n h&agrave;ng Shinhan Bank để kiểm tra lại th&ocirc;ng tin t&agrave;i khoản v&agrave; thực hiện đăng nhập lại.</p>\n' +
				     '<p><strong>4. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng Vietinbank</strong></p>\n' +
				     '<p>Vấn đề 1: Lỗi th&ocirc;ng tin kh&ocirc;ng tr&ugrave;ng khớp</p>\n' +
				     '<p>Hướng dẫn: Kiểm tra v&agrave; nhập ch&iacute;nh x&aacute;c lại c&aacute;c th&ocirc;ng tin in tr&ecirc;n thẻ v&agrave; số V&iacute; phải l&agrave; số điện thoại đăng k&yacute; nhận OTP của Vietinbank.</p>\n' +
				     '<p>Vấn đề 2: Số thẻ bạn d&ugrave;ng l&agrave; thẻ cũ c&oacute; đầu số l&agrave; 6201</p>\n' +
				     '<p>Hướng dẫn: Đến quầy Vietinbank để l&agrave;m thủ tục cấp lại thẻ mới c&oacute; đầu số 9704 15 để sử dụng</p>\n' +
				     '<p>Vấn đề 3: Số điện thoại đăng k&yacute; nhận OTP tại ng&acirc;n h&agrave;ng Vietinbank đ&atilde; kh&ocirc;ng c&ograve;n sử dụng</p>\n' +
				     '<p>Hướng dẫn: Đến quầy Vietinbank để l&agrave;m thủ tục đổi số điện thoại nhận OTP cho t&agrave;i khoản của bạn v&agrave; thử li&ecirc;n kết lại.</p>\n' +
				     '<p><strong>5. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng OCB</strong></p>\n' +
				     '<p>Vấn đề 1: Trang web li&ecirc;n kết ng&acirc;n h&agrave;ng kh&ocirc;ng hiển thị hoặc hiển thị trang trắng</p>\n' +
				     '<p>Hướng dẫn: Kiểm tra lại chất lượng kết nối mạng v&agrave; thử lại.</p>\n' +
				     '<p>Vấn đề 2: Kh&ocirc;ng thể đăng nhập t&agrave;i khoản OCB</p>\n' +
				     '<p>Hướng dẫn: Li&ecirc;n hệ hotline ng&acirc;n h&agrave;ng OCB để kiểm tra lại th&ocirc;ng tin t&agrave;i khoản internet banking v&agrave; thực hiện đăng nhập lại.</p>\n' +
				     '<p><strong>6. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng Eximbank</strong></p>\n' +
				     '<p>Vấn đề 1: Trang web li&ecirc;n kết ng&acirc;n h&agrave;ng kh&ocirc;ng hiển thị hoặc hiển thị trang trắng</p>\n' +
				     '<p>Hướng dẫn: Kiểm tra lại chất lượng kết nối mạng v&agrave; thử lại.</p>\n' +
				     '<p>Vấn đề 2: Kh&ocirc;ng thể đăng nhập t&agrave;i khoản Eximbank</p>\n' +
				     '<p>Hướng dẫn: Li&ecirc;n hệ hotline ng&acirc;n h&agrave;ng Eximbank để kiểm tra lại th&ocirc;ng tin t&agrave;i khoản v&agrave; thực hiện đăng nhập lại.</p>\n' +
				     '<p><strong>7. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng ACB</strong></p>\n' +
				     '<p>Vấn đề 1: Trang web li&ecirc;n kết ng&acirc;n h&agrave;ng kh&ocirc;ng hiển thị hoặc hiển thị trang trắng</p>\n' +
				     '<p>Hướng dẫn: Kiểm tra lại chất lượng kết nối mạng v&agrave; thử lại.</p>\n' +
				     '<p>Vấn đề 2: Kh&ocirc;ng thể đăng nhập t&agrave;i khoản ACB</p>\n' +
				     '<p>Hướng dẫn: Li&ecirc;n hệ hotline ng&acirc;n h&agrave;ng ACB để kiểm tra lại th&ocirc;ng tin t&agrave;i khoản v&agrave; thực hiện đăng nhập lại.</p>\n' +
				     '<p><strong>8. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng Vietcombank</strong></p>\n' +
				     '<p>Vấn đề 1: Trang web li&ecirc;n kết ng&acirc;n h&agrave;ng kh&ocirc;ng hiển thị hoặc hiển thị trang trắng</p>\n' +
				     '<p>Hướng dẫn: Kiểm tra lại chất lượng kết nối mạng v&agrave; thử lại.</p>\n' +
				     '<p>Vấn đề 2: Kh&ocirc;ng thể đăng nhập t&agrave;i khoản VCB</p>\n' +
				     '<p>Hướng dẫn: Li&ecirc;n hệ hotline ng&acirc;n h&agrave;ng VCB để kiểm tra lại th&ocirc;ng tin t&agrave;i khoản v&agrave; thực hiện đăng nhập lại.</p>\n' +
				     '<p>Vấn đề 3: Lỗi ""Kh&ocirc;ng t&igrave;m thấy số t&agrave;i khoản mặc định để sử dụng địch vụ n&agrave;y""</p>\n' +
				     '<p>Hướng dẫn: Đăng nhập trang Internet Banking -&gt; chọn ""C&agrave;i đặt phương thức nhận OTP"" -&gt; Chọn phương thức ""SMS"" -&gt; Chọn số điện thoại l&agrave; số V&iacute; GooPay của bạn -&gt; Nhập m&atilde; kiểm tra v&agrave; x&aacute;c nhận để ho&agrave;n tất -&gt; Thử lại</p>\n' +
				     '<p><strong>9. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng VIB</strong></p>\n' +
				     '<p>Vấn đề 1: Th&ocirc;ng tin kh&ocirc;ng ch&iacute;nh x&aacute;c hoặc kh&ocirc;ng nhớ th&ocirc;ng tin</p>\n' +
				     '<p>Hướng dẫn: Li&ecirc;n hệ hotline ng&acirc;n h&agrave;ng VIB để kiểm tra lại th&ocirc;ng tin t&agrave;i khoản v&agrave; thực hiện đăng nhập lại.</p>\n' +
				     '<p><strong>10. V&igrave; sao t&ocirc;i li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng kh&ocirc;ng th&agrave;nh c&ocirc;ng?</strong></p>\n' +
				     '<p>Trường hợp tr&ecirc;n c&oacute; thể do một trong c&aacute;c nguy&ecirc;n nh&acirc;n:</p>\n' +
				     '<p>Bạn kh&ocirc;ng nhập đ&uacute;ng số t&agrave;i khoản hoặc số thẻ ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>T&agrave;i khoản ng&acirc;n h&agrave;ng của bạn chưa đăng k&yacute; Internet Banking hoặc SMS Banking (v&agrave; dịch vụ E-commerce đối với Sacombank, Agribank).</p>\n' +
				     '<p>T&agrave;i khoản GooPay của bạn đang li&ecirc;n kết với 1 t&agrave;i khoản ng&acirc;n h&agrave;ng kh&aacute;c, hoặc t&agrave;i khoản ng&acirc;n h&agrave;ng của bạn đang li&ecirc;n kết với một t&agrave;i khoản GooPay kh&aacute;c.</p>\n' +
				     '<p>Số điện thoại đăng k&iacute; GooPay của bạn kh&ocirc;ng tr&ugrave;ng với số điện thoại SMS Banking đăng k&iacute; với ng&acirc;n h&agrave;ng, v&agrave;/hoặc chi tiết t&ecirc;n, CMND của bạn kh&ocirc;ng tr&ugrave;ng với th&ocirc;ng tin đ&atilde; lưu ở ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>Bạn cũng c&oacute; thể t&igrave;m hiểu th&ecirc;m về quy định v&agrave; c&aacute;ch li&ecirc;n kết với từng ng&acirc;n h&agrave;ng /huong-dan/lien-ket-ngan-hang-ctgr67</p>\n' +
				     '<p>Nếu cần hỗ trợ th&ecirc;m, vui l&ograve;ng để lại lời nhắn b&ecirc;n dưới cho bộ phận CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>- Nội dung: Li&ecirc;n kết ng&acirc;n h&agrave;ng kh&ocirc;ng th&agrave;nh c&ocirc;ng</p>\n' +
				     '<p>- T&ecirc;n ng&acirc;n h&agrave;ng li&ecirc;n kết</p>\n' +
				     '<p>- T&ecirc;n chủ thẻ/chủ t&agrave;i khoản ng&acirc;n h&agrave;ng""</p>\n' +
				     '<p><strong>11. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng BIDV</strong></p>\n' +
				     '<p>Vấn đề 1: B&aacute;o lỗi ""T&ecirc;n kh&aacute;ch h&agrave;ng kh&ocirc;ng đ&uacute;ng""</p>\n' +
				     '<p>Hướng dẫn: Li&ecirc;n hệ hotline BIDV 1900 9247 để hỏi lại ch&iacute;nh x&aacute;c th&ocirc;ng tin HỌ V&Agrave; T&Ecirc;N đang được lưu trong hệ thống BIDV v&agrave; thực hiện li&ecirc;n kết lại bằng th&ocirc;ng tin tr&ugrave;ng khớp.</p>\n' +
				     '<p>Vấn đề 2: Kh&ocirc;ng nhận được m&atilde; x&aacute;c thực OTP để li&ecirc;n kết</p>\n' +
				     '<p>Hướng dẫn: Kiểm tra tin nhắn của số điện thoại bạn đ&atilde; d&ugrave;ng để đăng k&yacute; nhận OTP tại BIDV.</p>\n' +
				     '<p><strong>12. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng VPBank</strong></p>\n' +
				     '<p>Vấn đề 1: Trang web li&ecirc;n kết ng&acirc;n h&agrave;ng kh&ocirc;ng hiển thị hoặc hiển thị trang trắng</p>\n' +
				     '<p>Hướng dẫn: Kiểm tra lại chất lượng kết nối mạng v&agrave; thử lại.</p>\n' +
				     '<p>Vấn đề 2: Kh&ocirc;ng thể đăng nhập t&agrave;i khoản VPBank</p>\n' +
				     '<p>Hướng dẫn: Li&ecirc;n hệ hotline ng&acirc;n h&agrave;ng VPBank để kiểm tra lại th&ocirc;ng tin t&agrave;i khoản v&agrave; thực hiện đăng nhập lại.</p>\n' +
				     '<p><strong>13. C&aacute;c vấn đề thường gặp khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng TPBank</strong></p>\n' +
				     '<p>Vấn đề 1: Trang web li&ecirc;n kết ng&acirc;n h&agrave;ng kh&ocirc;ng hiển thị hoặc hiển thị trang trắng</p>\n' +
				     '<p>Hướng dẫn: Kiểm tra lại chất lượng kết nối mạng v&agrave; thử lại.</p>\n' +
				     '<p>Vấn đề 2: Kh&ocirc;ng thể đăng nhập t&agrave;i khoản TPBank</p>\n' +
				     '<p>Hướng dẫn: Li&ecirc;n hệ hotline ng&acirc;n h&agrave;ng TPBank để kiểm tra lại th&ocirc;ng tin t&agrave;i khoản v&agrave; thực hiện đăng nhập lại.</p>\n' +
				     '<br/>' +
				     '<h1 style="font-size: 18px; font-weight: bold; color: #338FE3;">Sự cố khi nạp tiền hoặc r&uacute;t tiền</h1><br/>' +
				     '<p><strong>1. T&ocirc;i c&oacute; thể hủy giao dịch đang chờ xử l&yacute; hoặc thực hiện lại giao dịch được kh&ocirc;ng?</strong></p>\n' +
				     '<p>Bạn vui l&ograve;ng đợi kết quả xử l&yacute; giao dịch từ GooPay v&agrave; kh&ocirc;ng cần thực hiện lại giao dịch để tr&aacute;nh thanh to&aacute;n lặp 2 lần. Bạn cũng kh&ocirc;ng thể hủy giao dịch đang trong qu&aacute; tr&igrave;nh xử l&yacute;.</p>\n' +
				     '<p><strong>2. V&igrave; sao t&ocirc;i nạp tiền từ t&agrave;i khoản ng&acirc;n h&agrave;ng v&agrave;o GooPay kh&ocirc;ng th&agrave;nh c&ocirc;ng?</strong></p>' +
				     '<p>Trường hợp tr&ecirc;n c&oacute; thể do một trong c&aacute;c nguy&ecirc;n nh&acirc;n:</p>\n' +
				     '<li>T&agrave;i khoản ng&acirc;n h&agrave;ng kh&ocirc;ng hoạt động/ bị đ&oacute;ng băng. Vui l&ograve;ng li&ecirc;n hệ với bộ phận CSKH của ng&acirc;n h&agrave;ng của bạn để kiểm tra.</li>\n' +
				     '<li>Số dư trong t&agrave;i khoản ng&acirc;n h&agrave;ng của bạn đang thấp hơn gi&aacute; trị nạp tiền cộng số dư tối thiểu do ng&acirc;n h&agrave;ng quy định. Trong trường hợp n&agrave;y, bạn cần nạp th&ecirc;m tiền v&agrave;o t&agrave;i khoản ng&acirc;n h&agrave;ng rồi thực hiện lại giao dịch.</li>\n' +
				     '<li>T&agrave;i khoản GooPay của bạn vượt hạn mức nạp tiền trong ng&agrave;y khi thực hiện giao dịch. T&igrave;m hiểu th&ecirc;m về hạn mức giao dịch mỗi ng&agrave;y: /hoi-dap/han-muc-giao-dich-moi-ngay</li>\n' +
				     '<p>Nếu bạn kh&ocirc;ng gặp phải những vấn đề tr&ecirc;n, vui l&ograve;ng để lại lời nhắn b&ecirc;n dưới cho bộ phận CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>- Nội dung: Nạp tiền từ TK ng&acirc;n h&agrave;ng thất bại</p>\n' +
				     '<p>- M&atilde; giao dịch (M&atilde; TID)</p>\n' +
				     '<p>- T&ecirc;n ng&acirc;n h&agrave;ng thực hiện giao dịch</p>\n' +
				     '<p>- T&ecirc;n chủ thẻ/chủ t&agrave;i khoản ng&acirc;n h&agrave;ng</p>\n' +
				     '<p><strong>3. R&uacute;t tiền về ng&acirc;n h&agrave;ng: t&agrave;i khoản GooPay bị trừ tiền nhưng t&agrave;i khoản ng&acirc;n h&agrave;ng kh&ocirc;ng thay đổi</strong></p>\n' +
				     '<p>Xin bạn ho&agrave;n to&agrave;n y&ecirc;n t&acirc;m l&agrave; GooPay v&agrave; ng&acirc;n h&agrave;ng lu&ocirc;n tự động kiểm tra v&agrave; đối so&aacute;t để đảm bảo số dư của bạn được cập nhật ch&iacute;nh x&aacute;c. Với trường hợp n&agrave;y, tiền sẽ được ho&agrave;n lại v&agrave;o t&agrave;i khoản GooPay hoặc t&agrave;i khoản ng&acirc;n h&agrave;ng của bạn trong v&ograve;ng 48 giờ.</p>\n' +
				     '<p>Trường hợp sau 48 giờ bạn vẫn kh&ocirc;ng được ho&agrave;n tiền, vui l&ograve;ng để lại lời nhắn b&ecirc;n dưới cho bộ phận CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>- Nội dung: TK GooPay trừ tiền nhưng TK ng&acirc;n h&agrave;ng kh&ocirc;ng đổi</p>\n' +
				     '<p>- M&atilde; giao dịch (M&atilde; TID)</p>\n' +
				     '<p>- T&ecirc;n ng&acirc;n h&agrave;ng thực hiện giao dịch</p>\n' +
				     '<p>- T&ecirc;n chủ thẻ/chủ t&agrave;i khoản ng&acirc;n h&agrave;ng</p>\n' +
				     '<p><strong>4. T&agrave;i khoản bị trừ tiền nhưng giao dịch đang chờ xử l&yacute;</strong></p>\n' +
				     '<p>"C&aacute;c trường hợp giao dịch nạp/r&uacute;t tiền c&oacute; t&igrave;nh trạng ""đang chờ xử l&yacute;"" xảy ra do dữ liệu giữa GooPay v&agrave; ng&acirc;n h&agrave;ng chưa đồng bộ kịp thời.</p>\n' +
				     '<p>Khi gặp trường hợp n&agrave;y, bạn vui l&ograve;ng để lại lời nhắn b&ecirc;n dưới cho bộ phận CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>- Nội dung: Giao dịch chờ xử l&yacute;</p>\n' +
				     '<p>- M&atilde; giao dịch (M&atilde; TID)</p>\n' +
				     '<p>- T&ecirc;n ng&acirc;n h&agrave;ng thực hiện giao dịch</p>\n' +
				     '<p>- T&ecirc;n chủ thẻ/chủ t&agrave;i khoản ng&acirc;n h&agrave;ng</p>\n' +
				     '<p>CSKH sẽ hỗ trợ kiểm tra ch&iacute;nh x&aacute;c t&igrave;nh trạng giao dịch v&agrave; phản hồi với bạn.</p>\n' +
				     '<p>Bạn c&oacute; thể ho&agrave;n to&agrave;n y&ecirc;n t&acirc;m l&agrave; GooPay v&agrave; ng&acirc;n h&agrave;ng/đối t&aacute;c lu&ocirc;n kiểm tra v&agrave; đối so&aacute;t để đảm bảo số dư của bạn được cập nhật ch&iacute;nh x&aacute;c. Trong trường hợp giao dịch thất bại, tiền sẽ được ho&agrave;n lại v&agrave;o t&agrave;i khoản GooPay của bạn trong v&ograve;ng 48 giờ kh&ocirc;ng t&iacute;nh thứ 7, Chủ Nhật v&agrave; ng&agrave;y Lễ.</p>\n' +
				     '<p><strong>5. Nạp tiền từ ng&acirc;n h&agrave;ng: t&agrave;i khoản ng&acirc;n h&agrave;ng bị trừ tiền 2 lần</strong></p>\n' +
				     '<p>Rất c&oacute; thể bạn đ&atilde; v&ocirc; t&igrave;nh thực hiện lặp giao dịch 2 lần. Vui l&ograve;ng để lại lời nhắn b&ecirc;n dưới cho bộ phận CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>- Nội dung: TK ng&acirc;n h&agrave;ng bị trừ tiền 2 lần</p>\n' +
				     '<p>- M&atilde; giao dịch (M&atilde; TID)</p>\n' +
				     '<p>- T&ecirc;n ng&acirc;n h&agrave;ng thực hiện giao dịch</p>\n' +
				     '<p>- T&ecirc;n chủ thẻ/chủ t&agrave;i khoản ng&acirc;n h&agrave;ng</p>\n' +
				     '<p><strong>6. V&igrave; sao t&ocirc;i r&uacute;t tiền từ GooPay về t&agrave;i khoản ng&acirc;n h&agrave;ng kh&ocirc;ng th&agrave;nh c&ocirc;ng?</strong></p>' +
				     '<p>Trường hợp tr&ecirc;n c&oacute; thể do một trong c&aacute;c nguy&ecirc;n nh&acirc;n:</p>\n' +
				     '<ol>\n' +
				     '<p>1. T&agrave;i khoản ng&acirc;n h&agrave;ng kh&ocirc;ng hoạt động/ bị đ&oacute;ng băng. Vui l&ograve;ng li&ecirc;n hệ với bộ phận CSKH của ng&acirc;n h&agrave;ng của bạn để kiểm tra.</p>\n' +
				     '<p>2. T&agrave;i khoản GooPay của bạn vượt hạn mức r&uacute;t tiền trong ng&agrave;y khi thực hiện giao dịch. T&igrave;m hiểu th&ecirc;m về hạn mức r&uacute;t tiền /?questionId=41&amp;name=tại_đ&acirc;y.</p>\n' +
				     '</ol>\n' +
				     '<p>Nếu bạn kh&ocirc;ng gặp phải những vấn đề tr&ecirc;n, vui l&ograve;ng để lại lời nhắn b&ecirc;n dưới cho bộ phận CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>- Nội dung: R&uacute;t tiền về TK ng&acirc;n h&agrave;ng thất bại</p>\n' +
				     '<p>- M&atilde; giao dịch (M&atilde; TID)</p>\n' +
				     '<p>- T&ecirc;n ng&acirc;n h&agrave;ng thực hiện giao dịch</p>\n' +
				     '<p>- T&ecirc;n chủ thẻ/chủ t&agrave;i khoản ng&acirc;n h&agrave;ng</p>\n' +
				     '<p><strong>7. Nạp tiền từ ng&acirc;n h&agrave;ng: T&agrave;i khoản ng&acirc;n h&agrave;ng bị trừ tiền nhưng t&agrave;i khoản GooPay kh&ocirc;ng thay đổi</strong></p>\n' +
				     '<p>C&oacute; thể hệ thống chưa kịp cập nhật số dư trong t&agrave;i khoản GooPay. Bạn h&atilde;y đăng xuất t&agrave;i khoản GooPay v&agrave; đăng nhập lại để kiểm tra lại số dư.</p>\n' +
				     '<p>Nếu vẫn chưa thấy số dư thay đổi, xin bạn ho&agrave;n to&agrave;n y&ecirc;n t&acirc;m l&agrave; GooPay v&agrave; ng&acirc;n h&agrave;ng lu&ocirc;n tự động kiểm tra v&agrave; đối so&aacute;t để đảm bảo số dư của bạn được cập nhật ch&iacute;nh x&aacute;c. Tiền sẽ được ho&agrave;n lại v&agrave;o t&agrave;i khoản GooPay hoặc t&agrave;i khoản ng&acirc;n h&agrave;ng của bạn trong v&ograve;ng 48 giờ.</p>\n' +
				     '<p>Trường hợp sau 48 giờ bạn vẫn kh&ocirc;ng được ho&agrave;n tiền, vui l&ograve;ng để lại lời nhắn b&ecirc;n dưới cho bộ phận CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>- Nội dung: TK ng&acirc;n h&agrave;ng trừ tiền nhưng TK GooPay kh&ocirc;ng đổi</p>\n' +
				     '<p>- M&atilde; giao dịch (M&atilde; TID)</p>\n' +
				     '<p>- T&ecirc;n ng&acirc;n h&agrave;ng thực hiện giao dịch</p>\n' +
				     '<p>- T&ecirc;n chủ thẻ/chủ t&agrave;i khoản ng&acirc;n h&agrave;ng</p>\n' +
				     '<p><strong>8. T&ocirc;i c&oacute; thể chuyển tiền cho người chưa c&oacute; t&agrave;i khoản V&iacute; GooPay được kh&ocirc;ng?</strong></p>\n' +
				     '<p>Được. L&uacute;c n&agrave;y, người được nhận tiền sẽ nhận được th&ocirc;ng b&aacute;o qua tin nhắn EMS k&egrave;m đường dẫn tải ứng dụng V&iacute; GooPay. Trong v&ograve;ng 48 giờ, người nhận chỉ cần tải v&agrave; đăng k&yacute; t&agrave;i khoản V&iacute; GooPay để nhận tiền.</p>\n' +
				     '<p>Trường hợp người được nhận tiền chưa kịp đăng k&yacute; t&agrave;i khoản, số tiền đ&atilde; chuyển sẽ tự động được ho&agrave;n trả v&agrave;o t&agrave;i khoản của người gửi.</p>\n' +
				     '<br/>' +
				     '<h1 style="font-size: 18px; font-weight: bold; color: #338FE3;">Sự cố khi tham gia khuyến m&atilde;i</h1><br/>' +
				     '<p><strong>1. T&ocirc;i kh&ocirc;ng sử dụng được thẻ qu&agrave; tặng</strong></p>\n' +
				     '<p>Mỗi thẻ qu&agrave; tặng c&oacute; điều kiện sử dụng, dịch vụ &aacute;p dụng v&agrave; thời hạn sử dụng nhất định. Bạn vui l&ograve;ng kiểm tra lại chi tiết thẻ qu&agrave; tặng để chắc chắn bạn đ&atilde; sử dụng thẻ qu&agrave; tặng đ&uacute;ng c&aacute;ch. Xem hướng dẫn sử dụng thẻ qu&agrave; tặng /?questionId=114&amp;name=tại_đ&acirc;y.Nếu vẫn c&oacute; thắc mắc, bạn vui l&ograve;ng để lại lời nhắn b&ecirc;n dưới cho bộ phận CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>- Nội dung: Kh&ocirc;ng sử dụng được thẻ qu&agrave; tặng</p>\n' +
				     '<p>- T&ecirc;n CTKM</p>\n' +
				     '<p>- Dịch vụ d&ugrave;ng qu&agrave; tặng</p>\n' +
				     '<p>- Thời gian thực hiện</p>\n' +
				     '<p>- C&acirc;u th&ocirc;ng b&aacute;o tr&ecirc;n ứng dụng (đ&iacute;nh k&egrave;m h&igrave;nh nếu c&oacute;)</p>\n' +
				     '<p><strong>2. T&ocirc;i nhận được th&ocirc;ng b&aacute;o m&atilde; khuyến m&atilde;i kh&ocirc;ng hợp lệ</strong></p>\n' +
				     '<p>C&aacute;c chương tr&igrave;nh khuyến m&atilde;i (CTKM) thường c&oacute; những điều kiện tham gia như:</p>\n' +
				     '<p>- Nhận thưởng 1 lần duy nhất: Mỗi kh&aacute;ch h&agrave;ng tương ứng với 1 CMND, 1 t&agrave;i khoản GooPay, 1 t&agrave;i khoản/ thẻ ng&acirc;n h&agrave;ng chỉ được nhận thưởng 1 lần trong chương tr&igrave;nh. V&iacute; dụ, nếu bạn sử dụng c&ugrave;ng 1 t&agrave;i khoản ng&acirc;n h&agrave;ng cho 2 t&agrave;i khoản GooPay kh&aacute;c nhau th&igrave; bạn cũng chỉ được nhận thưởng 1 lần.</p>\n' +
				     '<p>- C&aacute;c CTKM kh&ocirc;ng &aacute;p dụng đồng thời: Mỗi kh&aacute;ch h&agrave;ng khi đ&atilde; nhận thưởng của 1 CTKM n&agrave;o đ&oacute; sẽ kh&ocirc;ng được tham gia một v&agrave;i CTKM kh&aacute;c.</p>\n' +
				     '<p>Bạn vui l&ograve;ng kiểm tra lại thể lệ c&aacute;c CTKM /tin-tuc/khuyen-mai&amp;name=tại_đ&acirc;y. Nếu bạn vẫn c&oacute; thắc mắc, bạn vui l&ograve;ng để lại lời nhắn b&ecirc;n dưới cho bộ phận CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>- Nội dung: M&atilde; khuyến m&atilde;i kh&ocirc;ng hợp lệ</p>\n' +
				     '<p>- T&ecirc;n CTKM</p>\n' +
				     '<p>- Thời gian nhập m&atilde;</p>\n' +
				     '<p>- C&acirc;u th&ocirc;ng b&aacute;o tr&ecirc;n ứng dụng (đ&iacute;nh k&egrave;m h&igrave;nh nếu c&oacute;)</p>\n' +
				     '<p><strong>3. T&ocirc;i kh&ocirc;ng nhận được qu&agrave; tặng d&ugrave; đ&atilde; l&agrave;m theo hướng dẫn</strong></p>\n' +
				     '<p>C&aacute;c chương tr&igrave;nh khuyến m&atilde;i (CTKM) thường c&oacute; c&aacute;c ""nhiệm vụ"" v&agrave; bạn phải thực hiện đầy đủ theo y&ecirc;u cầu để được nhận thưởng. V&iacute; dụ: Khi tham gia CTKM Li&ecirc;n kết t&agrave;i khoản, bạn c&oacute; thể sẽ qu&ecirc;n bước nạp tiền sau khi li&ecirc;n kết t&agrave;i khoản th&agrave;nh c&ocirc;ng.</p>\n' +
				     '<p>Bạn vui l&ograve;ng kiểm tra lại thể lệ c&aacute;c CTKM /tin-tuc/khuyen-mai&amp;name=tại_đ&acirc;y. Nếu vẫn c&oacute; thắc mắc, bạn vui l&ograve;ng để lại lời nhắn b&ecirc;n dưới cho bộ phận CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>- Nội dung: Kh&ocirc;ng nhận được qu&agrave; CTKM</p>\n' +
				     '<p>- T&ecirc;n CTKM</p>\n' +
				     '<p>- Thời gian tham gia</p>'
		     }}
		/>
	</div>
);

export default FAQIncident;
