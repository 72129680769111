import React from 'react';
import './style.css';
import Underline from '../Common/Underline/Underline';
import {DOWNLOAD_APP_LINK, PATH} from '../../constants';
import NewLink from "../NewLink/NewLink";

const Footer = () => {
	const items = [
		{
			icon: '/img/icon/bank-institution.png',
			title: 'Bảo chứng ngân hàng',
			description: 'Được Ngân Hàng Nhà Nước Việt Nam cấp phép và quản lý',
		},
		{
			icon: '/img/icon/shield.png',
			title: 'Bảo mật đa tầng',
			description: 'Bảo mật kép với mã OTP và mật khẩu 6 chữ số',
		},
		{
			icon: '/img/icon/spark-picture.png',
			title: 'Chương trình hội viên',
			description: 'Tích điểm trên từng chuyến đi/ đơn hàng cùng nhiều ưu đãi hấp dẫn',
		},
		{
			icon: '/img/icon/screen-broadcast.png',
			title: 'Tối ưu trải nghiệm',
			description: 'Tích hợp công nghệ Tokenization tự động lưu trữ và bảo mật dữ liệu',
		},
	];
	
	return (
		<footer className="text-gray-600">
			<div className="">
				<div className="gradient-1 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 py-8 px-[10px] lg:px-[150px]">
					{items.map((item, index) => (
						<div key={index} className="flex flex-col items-center text-center">
							<img src={item.icon} alt={item.title}/>
							<h3 className="text-white text-xs font-semibold mb-2">{item.title}</h3>
							<p className="text-xs text-white">{item.description}</p>
						</div>
					))}
				</div>
				
				<div
					className="gradient-2 flex flex-col md:flex-row gap-8 py-5 md:py-10 px-[10px] md:px-[150px] justify-center">
					<div className="flex flex-col items-start lg:items-center md:mt-8 lg:hidden">
						<h3 className="text-xs font-semibold mb-4 text-white">Thông tin</h3>
						<NewLink className="text-xs text-white" to={PATH.POLICY_USAGE}>Điều khoản sử dụng</NewLink>
						<NewLink className="text-xs text-white mt-1" to={PATH.POLICY_PRIVACY}>Chính sách bảo vệ dữ liệu</NewLink>
						<NewLink className="text-xs text-white mt-1" to={PATH.FAQ}>Hỏi đáp</NewLink>
					</div>
					
					<div className="flex flex-col items-start md:w-[50%]">
						<div className='flex justify-center items-center gap-2 text-sm font-bold text-white'>
							<NewLink to={PATH.HOME}>
								<img src="/img/logo-goopay-light.png" alt="Logo" className="h-12 mb-4"/>
							</NewLink>
							<span className='mb-[-10px]'>Ví điện tử ngành vận tải</span>
						</div>
						<p className="text-white text-xs mb-4">
							Bản quyền 2024. CÔNG TY CỔ PHẦN THANH TOÁN TRỰC TUYẾN TOÀN CẦU<br/>
							Địa chỉ: 76-82, Trần Hưng Đạo, P. Phạm Ngũ Lão, Quận 1, TPHCM<br/>
							Giấy ĐKKD: số 0107768570 do Sở Kế hoạch và Đầu tư Thành phố Hà nội cấp lần đầu ngày 20/03/2017<br/>
							Giấy phép hoạt động: cung ứng dịch vụ TGTT số 75/GP/NHNN do Thống đốc NHNN cấp ngày 15/11/2021<br/>
							Hotline: 19006038<br/>
							Website: https://goopay.vn<br/>
						</p>
						<Underline className="h-2 w-[169px]" style={{width: '169px', height: '8px'}}/>
					</div>
					
					<div className="hidden mt-8 md:flex justify-center md:w-[20%]">
						<div className="flex flex-col items-start">
							<h3 className="text-xs font-semibold mb-4 text-white">Thông tin</h3>
							<NewLink className="text-xs text-white" to={PATH.POLICY_USAGE}>Điều khoản sử dụng</NewLink>
							<NewLink className="text-xs text-white mt-1" to={PATH.POLICY_PRIVACY}>Chính sách bảo vệ dữ liệu</NewLink>
							<NewLink className="text-xs text-white mt-1" to={PATH.FAQ}>Hỏi đáp</NewLink>
						</div>
					</div>
					
					<div className="flex flex-col items-start md:mt-8 md:w-[30%]">
						<h3 className="text-xs font-semibold mb-4 text-white">Tải ứng dụng GooPay</h3>
						<div className="flex items-center mb-4">
							<img
								className="w-[100px] mr-4 bg-white"
								src="/img/download-qrcode.png"
								alt="QR Code"/>
							<div className="flex flex-col">
								<NewLink to={DOWNLOAD_APP_LINK.IOS} target='_blank'>
									<img src="/img/download-ios.png" alt=""/>
								</NewLink>
								<NewLink to={DOWNLOAD_APP_LINK.ANDROID} target='_blank'>
									<img src="/img/download-android.png" alt=""/>
								</NewLink>
							</div>
						</div>
						<div className="flex md:justify-center justify-start space-x-4">
							<NewLink to='https://www.facebook.com/GoopayJSC?locale=vi_VN' target='_blank'>
								<img className="w-4 h-4" src="/img/icon/fb.png" alt="fb"/>
							</NewLink>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
