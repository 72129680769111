// src/components/Home.js
import React from 'react';
import HiringBanner from '../components/Banner/HiringBanner';
import Button from '../components/Common/Button';
import {PATH, VARIANT} from '../constants';
import cn from 'classnames';
import {hiringData} from "../data/hiring";
import NewLink from "../components/NewLink/NewLink";

const Hiring = () => (
	<div className="mx-auto w-full animate-fadeIn">
		<HiringBanner/>
		<div className='py-[30px] md:mx-auto md:w-[1044px] mx-4'>
			<h2 className='w-fit text-2xl font-bold'>Danh sách tuyển dụng</h2>
			<div className="">
				{
					hiringData.map((item, index) => (
						<div
							className={cn('flex flex-col md:flex-row text-sm mt-5 gap-5 p-2 justify-start md:justify-center items-start md:items-center w-fit', index % 2 !== 0 && 'bg-[#FAFAFA]')}>
							<div>
								<div className='font-bold w-[360px] md:mb-2'>{item.title}</div>
								<div className='text-xs'>{`Số lượng: ${item.quantity}`}</div>
							</div>
							<div className=''>
								<div className='font-bold w-[184px md:mb-2'>Nơi làm việc</div>
								<div className='text-xs'>{item.office}</div>
							</div>
							<div>
								<div className='font-bold w-[161px] md:mb-2'>Lương</div>
								<div className='text-xs'>{item.salary}</div>
							</div>
							<div>
								<div className='font-bold w-[107px] md:mb-2'>Ngày hết hạn</div>
								<div className='text-xs'>{item.expired}</div>
							</div>
							<NewLink to={`${PATH.HIRING}/${item.slug}`}>
								<Button className='w-[124px] h-[40px]' variant={VARIANT.PRIMARY}>{item.button}</Button>
							</NewLink>
						</div>
					))
				}
			</div>
		</div>
	</div>
);

export default Hiring;
