// src/components/Home.js
import React from 'react';
import FAQBanner from '../../components/Banner/FAQBanner';
import {PATH} from "../../constants";
import NewLink from "../../components/NewLink/NewLink";

const FAQAccount = () => (
	<div className="mx-auto w-full animate-fadeIn text-sm">
		<FAQBanner title='QUẢN LÝ ỨNG DỤNG
				VÀ TÀI KHOẢN'/>
		<div className='flex px-8 md:px-[256px] pt-8 md:pb-8 justify-center items-center'>
			<NewLink to={PATH.FAQ} className='hidden md:block'>
				<img className='w-[20px]' src="/img/icon/back.png" alt="back"/>
			</NewLink>
			<h2 className='text-center w-full font-bold text-xl uppercase text-primary'>QUẢN LÝ ỨNG DỤNG
				VÀ TÀI KHOẢN</h2>
		</div>
		<div className='px-8 md:px-[256px] py-8'
		     dangerouslySetInnerHTML={{
			     __html: '' +
				     '<div>\n' +
				     '    <div style="clear:both;">\n' +
				     '        <p style="">&nbsp;</p>\n' +
				     '    </div>\n' +
				     '    <p style=" text-align:center; line-height:115%; font-size:14pt; color: #338FE3"><strong>Tải, x&oacute;a, cập nhật ứng dụng</strong></p>\n' +
				     '    <p style=" text-align:center; line-height:115%; font-size:14pt;"><strong>&nbsp;</strong></p>\n' +
				     '    <ol type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold; list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">Khi gỡ bỏ ứng dụng tr&ecirc;n điện thoại th&igrave; tiền trong t&agrave;i khoản GooPay v&agrave; th&ocirc;ng tin giao dịch c&oacute; bị mất hay kh&ocirc;ng?</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Việc gỡ bỏ ứng dụng kh&ocirc;ng ảnh hưởng đến t&agrave;i khoản V&iacute; GooPay v&agrave; c&aacute;c th&ocirc;ng tin giao dịch kh&aacute;c, v&igrave; to&agrave;n bộ dữ liệu đ&atilde; được ghi nhận v&agrave; lưu trữ tr&ecirc;n hệ thống m&aacute;y chủ tại trung t&acirc;m.</p>\n' +
				     '    <p style="">Ngay khi bạn c&agrave;i đặt lại ứng dụng, c&aacute;c th&ocirc;ng tin trong t&agrave;i khoản sẽ được phục hồi như cũ.</p>\n' +
				     '    <ol start="2" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">T&ocirc;i c&oacute; thể kiểm tra phi&ecirc;n bản ứng dụng GooPay ở đ&acirc;u?</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Bước 1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng, chọn &quot;V&iacute; của t&ocirc;i&quot;</p>\n' +
				     '    <p style="">Bước 2: Nhấn v&agrave;o &quot;C&agrave;i đặt&quot; ở g&oacute;c phải tr&ecirc;n c&ugrave;ng</p>\n' +
				     '    <p style="">Bước 3: Nhấn v&agrave;o &quot;Th&ocirc;ng tin ứng dụng&quot;</p>\n' +
				     '    <p style="">Tại đ&acirc;y, bạn c&oacute; thể đọc được phi&ecirc;n bản ứng dụng hiển thị ngay b&ecirc;n dưới logo GooPay.</p>\n' +
				     '    <ol start="3" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">C&aacute;ch cập nhật phi&ecirc;n bản ứng dụng GooPay mới nhất</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Bạn h&atilde;y v&agrave;o CH Play (d&agrave;nh cho Android) hoặc App Store (d&agrave;nh cho IOS) v&agrave; nhập &quot;GooPay&quot; để t&igrave;m ứng dụng V&iacute; GooPay, sau đ&oacute; chọn &quot;Cập nhật&quot;.</p>\n' +
				     '    <p style="">Ch&uacute; &yacute;: bạn c&oacute; thể v&agrave;o mục &quot;C&agrave;i đặt&quot; tr&ecirc;n điện thoại của bạn v&agrave; chọn chế độ tự động cập nhật ứng dụng khi c&oacute; phi&ecirc;n bản mới.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style=" text-align:center; line-height:115%; font-size:14pt; color: #338FE3"><strong>Đăng k&yacute;, đăng nhập v&agrave; đổi t&agrave;i khoản</strong></p>\n' + '<br/>' +
				     '    <ol type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" line-height:115%; padding-left:2.99pt;  font-weight:bold;">T&ocirc;i c&oacute; thể đăng k&yacute; bao nhi&ecirc;u t&agrave;i khoản GooPay?</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Một kh&aacute;ch h&agrave;ng (tương ứng với 1 CCCD) c&oacute; thể đăng k&iacute; tối đa 3 t&agrave;i khoản GooPay để sử dụng.</p>\n' +
				     '    <ol start="2" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" line-height:115%; padding-left:2.99pt;  font-weight:bold;">T&ocirc;i c&oacute; thể đăng nhập v&agrave;o c&ugrave;ng một t&agrave;i khoản GooPay tr&ecirc;n nhiều thiết bị được kh&ocirc;ng?</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Một t&agrave;i khoản GooPay chỉ c&oacute; thể đăng nhập tr&ecirc;n một thiết bị tại một thời điểm. Khi bạn sử dụng thiết bị mới để đăng nhập v&agrave;o t&agrave;i khoản, ứng dụng GooPay tr&ecirc;n thiết bị hiện tại sẽ tự động đăng xuất khỏi t&agrave;i khoản.</p>\n' +
				     '    <ol start="3" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" line-height:115%; padding-left:2.99pt;  font-weight:bold;">T&ocirc;i c&oacute; thể đổi số điện thoại đăng k&yacute; t&agrave;i khoản GooPay của t&ocirc;i được kh&ocirc;ng?</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Hiện tại, V&iacute; GooPay kh&ocirc;ng hỗ trợ chuyển t&agrave;i khoản GooPay từ số điện thoại n&agrave;y sang một số điện thoại kh&aacute;c. Nếu c&oacute; nhu cầu thay đổi số điện thoại, bạn c&oacute; thể tạo t&agrave;i khoản GooPay mới với số điện thoại mới, v&agrave; chuyển tiền từ t&agrave;i khoản GooPay cũ sang t&agrave;i khoản GooPay mới. Sau đ&oacute;, bạn c&oacute; thể hủy t&agrave;i khoản GooPay cũ bằng c&aacute;ch li&ecirc;n hệ với bộ phận CSKH qua số 19006038 để được hỗ trợ hủy t&agrave;i khoản.</p>\n' +
				     '    <ol start="4" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" line-height:115%; padding-left:2.99pt;  font-weight:bold;">T&ocirc;i c&oacute; thể đăng nhập v&agrave;o nhiều t&agrave;i khoản GooPay tr&ecirc;n c&ugrave;ng một thiết bị được kh&ocirc;ng?</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Mỗi thiết bị chỉ c&oacute; thể đăng nhập v&agrave;o một t&agrave;i khoản GooPay tại một thời điểm. Để đăng nhập v&agrave;o t&agrave;i khoản GooPay kh&aacute;c tr&ecirc;n c&ugrave;ng thiết bị, bạn cần phải đăng xuất khỏi t&agrave;i khoản hiện tại tr&ecirc;n thiết bị đ&oacute; rồi đăng nhập v&agrave;o t&agrave;i khoản mới.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Lưu &yacute;: bạn chỉ c&oacute; thể đăng nhập tối đa 3 t&agrave;i khoản tr&ecirc;n c&ugrave;ng một thiết bị di động trong 1 th&aacute;ng. Theo quy định bảo mật của GooPay, nếu bạn đăng nhập qu&aacute; 3 thiết bị, ứng dụng GooPay tr&ecirc;n điện thoại của bạn sẽ tự động kh&oacute;a.</p>\n' +
				     '    <ol start="5" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">C&aacute;ch đăng nhập v&agrave;o t&agrave;i khoản GooPay kh&aacute;c</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Bước 1: Tại m&agrave;n h&igrave;nh đăng nhập, chọn &quot;Đổi SĐT&quot;.</p>\n' +
				     '    <p style="">Bước 2: Nhập số điện thoại rồi chọn &quot;Tiếp tục&quot;</p>\n' +
				     '    <p style="">Bước 3: Nhập m&atilde; OTP (m&atilde; x&aacute;c thực được gửi qua tin nhắn đến số điện thoại bạn nhập)</p>\n' +
				     '    <p style="">Bước 4: Nhập mật khẩu đăng nhập</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style=" text-align:center; line-height:115%; font-size:14pt; color: #338FE3"><strong>Mật khẩu, x&aacute;c thực t&agrave;i khoản &amp; email</strong></p>\n' +
				     '    <p style=" text-align:center;">&nbsp;</p>\n' +
				     '    <ol type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">M&atilde; OTP l&agrave; g&igrave;? M&atilde; OTP được gửi cho t&ocirc;i trong những trường hợp n&agrave;o?</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">M&atilde; x&aacute;c thực OTP - One Time Password l&agrave; loại mật khẩu sử dụng một lần v&agrave; được coi l&agrave; lớp bảo vệ thứ hai cho c&aacute;c t&agrave;i khoản ng&acirc;n h&agrave;ng điện tử, thanh to&aacute;n trực tuyến hay e-mail, mạng x&atilde; hội.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">M&atilde; OTP được gửi qua tin nhắn SMS đến đ&uacute;ng số điện thoại đăng k&yacute; t&agrave;i khoản GooPay m&agrave; bạn đang sử dụng. GooPay sẽ gửi m&atilde; OTP cho bạn trong những trường hợp:</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">1. Đăng k&iacute; t&agrave;i khoản mới</p>\n' +
				     '    <p style="">2. Đổi số điện thoại/ đăng nhập t&agrave;i khoản GooPay kh&aacute;c</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Trường hợp khi bạn li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng với t&agrave;i khoản GooPay, bạn sẽ nhận được m&atilde; OTP gửi đến từ ng&acirc;n h&agrave;ng của bạn.</p>\n' +
				     '    <ol start="2" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">T&ocirc;i kh&ocirc;ng nhận được m&atilde; OTP</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Trong trường hợp bạn kh&ocirc;ng nhận được m&atilde; OTP trong v&ograve;ng 30 gi&acirc;y, bạn c&oacute; thể nhấn v&agrave;o &quot;Gửi lại&quot; để hệ thống gửi lại một m&atilde; OTP kh&aacute;c cho bạn.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Trường hợp vẫn kh&ocirc;ng nhận được m&atilde; OTP, c&oacute; thể do lỗi kết nối mạng. Vui l&ograve;ng thử lại sau một l&uacute;c, hoặc li&ecirc;n hệ với bộ phận CSKH qua số 19006038 để được hỗ trợ.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <ol start="3" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">Quy định về việc đổi mật khẩu</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Mỗi 6 th&aacute;ng GooPay sẽ y&ecirc;u cầu bạn đổi mật khẩu để đảm bảo sự bảo mật t&agrave;i khoản. Mật khẩu mới kh&ocirc;ng được tr&ugrave;ng với mật khẩu hiện tại.</p>\n' +
				     '    <ol start="4" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">C&aacute;ch đổi mật khẩu</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Bước 1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng chọn &apos;V&iacute; của t&ocirc;i&apos;, chọn &apos;C&agrave;i đặt&apos; ở g&oacute;c phải tr&ecirc;n c&ugrave;ng</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Bước 2: Chọn &apos;Thiết lập bảo mật t&agrave;i khoản&apos; rồi chọn &apos;Đổi mật khẩu&apos;</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Bước 3: Nhập mật khẩu hiện tại rồi mật khẩu mới 2 lần.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Bước 4: Chọn &apos;X&aacute;c nhận&apos;.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Lưu &yacute;: Mật khẩu mới kh&ocirc;ng được tr&ugrave;ng với mật khẩu hiện tại. GooPay khuy&ecirc;n bạn Kh&ocirc;ng n&ecirc;n chọn mật khẩu đơn giản theo ng&agrave;y sinh v&agrave; số điện thoại của m&igrave;nh, kh&ocirc;ng chọn mật khẩu &quot;123456&quot; hoặc gồm 6 chữ số giống nhau.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <ol start="5" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">C&aacute;ch cập nhật th&ocirc;ng tin c&aacute; nh&acirc;n</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Để c&oacute; thể cập nhật th&ocirc;ng tin c&aacute; nh&acirc;n bạn vui l&ograve;ng thực hiện theo hướng dẫn sau:</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Bước 1: Đăng nhập v&agrave;o t&agrave;i khoản GooPay</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Bước 2: Chọn mục &quot;V&iacute; của t&ocirc;i&quot; ở ph&iacute;a dưới ch&acirc;n m&agrave;n h&igrave;nh ch&iacute;nh</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Bước 3: Chọn mục &quot;Th&ocirc;ng tin C&aacute; nh&acirc;n&quot;</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Bước 4: Tiến h&agrave;nh bổ sung/thay đổi th&ocirc;ng tin c&aacute; nh&acirc;n của m&igrave;nh</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <ol start="6" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">C&aacute;ch x&aacute;c thực t&agrave;i khoản</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Để thao t&aacute;c x&aacute;c thực t&agrave;i khoản, bạn h&atilde;y thực hiện theo c&aacute;c bước hướng dẫn sau:</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Đăng nhập v&agrave;o ứng dụng:</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">B1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng, bạn c&oacute; thể chọn 1 trong 3 c&aacute;ch sau để cập nhật th&ocirc;ng tin chủ V&iacute; như sau:</p>\n' +
				     '    <p style="">C&aacute;ch 1: Tại m&agrave;n h&igrave;nh ch&iacute;nh, chọn biểu tượng h&igrave;nh đại diện ở g&oacute;c tr&ecirc;n b&ecirc;n phải để v&agrave;o &quot;&quot;Trang c&aacute; nh&acirc;n của t&ocirc;i&quot;&quot; &rarr; chọn &quot;&quot;Chụp CCCD ngay!&quot;&quot;</p>\n' +
				     '    <p style=""><span style="">C&aacute;ch 2: Tại m&agrave;n h&igrave;nh ch&iacute;nh, chọn &ldquo;V&iacute; của t&ocirc;i&rdquo; &rarr; chọn &quot;&quot;Chụp CCCD ngay!&quot;&quot;</span></p>\n' +
				     '    <p style=""><span style="">C&aacute;ch 3:Tại m&agrave;n h&igrave;nh ch&iacute;nh, chọn &ldquo;V&iacute; của t&ocirc;i&rdquo; &rarr; chọn mục &ldquo;Trung t&acirc;m bảo mật&rdquo; &rarr; chọn &quot;&quot;X&aacute;c thực ngay&quot;&quot; trong phần &quot;&quot;Th&ocirc;ng tin x&aacute;c thực t&agrave;i khoản&quot;&quot;</span></p>\n' +
				     '    <p style="">B2: Chụp ảnh CCCD mặt trước- mặt sau hoặc Hộ chiếu - trang 2 v&agrave; 3</p>\n' +
				     '    <p style="">B3: Kiểm tra th&ocirc;ng tin.</p>\n' +
				     '    <p style="">C&aacute;c th&ocirc;ng tin từ CCCD/ Hộ chiếu sẽ được tự động điền v&agrave;o c&aacute;c &ocirc; th&ocirc;ng tin, bạn vui l&ograve;ng kiểm tra ch&iacute;nh x&aacute;c th&ocirc;ng tin v&agrave; nhấn &quot;&quot;X&aacute;c thực&quot;&quot; để tiếp tục.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Lưu &yacute;: Theo Th&ocirc;ng tư 23/2019/TT-NHNN, th&ocirc;ng tin chủ V&iacute; GooPay bắt buộc tr&ugrave;ng khớp th&ocirc;ng tin li&ecirc;n kết ng&acirc;n h&agrave;ng. Do đ&oacute;, nếu bạn chưa cung cấp th&ocirc;ng tin ng&acirc;n h&agrave;ng, h&atilde;y li&ecirc;n kết ng&acirc;n h&agrave;ng ngay để c&oacute; thể tiếp tục sử dụng c&aacute;c dịch vụ của V&iacute; GooPay nh&eacute;!</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">B4: Thực hiện x&aacute;c thực khu&ocirc;n mặt</p>\n' +
				     '    <p style="">Giữ điện thoại cố định, đưa khu&ocirc;n mặt của bạn v&agrave;o khung h&igrave;nh tr&ograve;n v&agrave; sau đ&oacute; di chuyển khu&ocirc;n mặt từ từ theo hướng dẫn. Sau khi ho&agrave;n th&agrave;nh bạn h&atilde;y trở lại m&agrave;n h&igrave;nh ch&iacute;nh để chờ kết quả nh&eacute;.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">GooPay khuyến kh&iacute;ch bạn tiếp tục ho&agrave;n th&agrave;nh bước Bảo mật tối đa bằng 1 trong 2 c&aacute;ch sau:</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Tại mục &quot;&quot;Trung t&acirc;m bảo mật&quot;&quot; phần &ldquo;Thiết lập bảo mật tối đa&rdquo;, bạn c&oacute; thể chọn bổ sung th&ecirc;m h&igrave;nh thức tăng cường bảo mật cho V&iacute; như sau:</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Chọn &ldquo;T&agrave;i khoản tin tưởng&rdquo; hoặc, Chọn &ldquo;C&acirc;u hỏi bảo mật&rdquo;</p>\n' +
				     '    <p style="">Để ho&agrave;n tất thiết lập bạn nhấn tiếp mục &quot;&quot;Thiết lập ngay&rdquo;.</p>\n' +
				     '    <ol start="7" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">C&aacute;ch x&aacute;c thực email</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">* C&aacute;ch 1: X&aacute;c thực email ngay sau khi đăng k&yacute; t&agrave;i khoản GooPay. Bạn sẽ nhận được email ch&agrave;o mừng từ GooPay k&egrave;m với y&ecirc;u cầu x&aacute;c thực email. Vui l&ograve;ng đăng nhập v&agrave;o email của bạn để ho&agrave;n tất x&aacute;c thực.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">* C&aacute;ch 2: X&aacute;c thực email từ tr&ecirc;n ứng dụng. C&aacute;c bước thực hiện:</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Bước 1: Từ m&agrave;n h&igrave;nh ch&iacute;nh, chọn &quot;V&iacute; của t&ocirc;i&quot; rồi chọn &quot;Th&ocirc;ng tin c&aacute; nh&acirc;n&quot;.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Bước 2: Kiểm tra mục &quot;Email&quot;, nếu hiển thị c&acirc;u th&ocirc;ng b&aacute;o :&quot;X&aacute;c thực email để kh&ocirc;i phục t&agrave;i khoản khi qu&ecirc;n mật khẩu&quot; nghĩa l&agrave; email của bạn chưa được x&aacute;c thực.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Bước 3: Nhấn n&uacute;t &quot;X&aacute;c thực&quot;, GooPay sẽ gửi 1 email đến địa chỉ email bạn đ&atilde; nhập.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Bước 4: Vui l&ograve;ng đăng nhập v&agrave;o email của bạn để ho&agrave;n tất x&aacute;c thực.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Sau khi bạn đ&atilde; x&aacute;c thực th&agrave;nh c&ocirc;ng, hệ thống sẽ tự động cập nhật email đ&atilde; x&aacute;c thực trong mục &quot;Th&ocirc;ng tin c&aacute; nh&acirc;n&quot;.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <ol start="8" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">C&aacute;ch lấy lại mật khẩu</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Trường hợp 1: T&agrave;i khoản GooPay đ&atilde; li&ecirc;n kết với t&agrave;i khoản/ thẻ ng&acirc;n h&agrave;ng bất kỳ, hoặc số dư trong t&agrave;i khoản GooPay &ge; 200.000đ</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Phương thức 1: Nhận diện khu&ocirc;n mặt hoặc x&aacute;c thực h&igrave;nh CCCD (Căn cước công d&acirc;n).</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Sau khi chọn &ldquo;QU&Ecirc;N MẬT KHẨU&rdquo;, phần mềm nhận diện khu&ocirc;n mặt hoặc x&aacute;c thực h&igrave;nh CCCD tr&ecirc;n hệ thống GooPay sẽ hiện ra. Ho&agrave;n th&agrave;nh c&aacute;c bước theo hướng dẫn, bạn sẽ nhận được m&atilde; OTP qua cuộc gọi từ GooPay về số điện thoại đ&atilde; đăng k&yacute;, để c&oacute; thể c&agrave;i đặt lại mật khẩu mới.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Phương thức 2: Lấy lại mật khẩu bằng Email.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Nếu bạn đ&atilde; x&aacute;c thực email tr&ecirc;n hệ thống của GooPay, bạn c&oacute; thể dễ d&agrave;ng lấy lại mật khẩu th&ocirc;ng qua địa chỉ email đ&atilde; cung cấp. GooPay sẽ gửi email x&aacute;c nhận đổi mật khẩu đến địa chỉ email bạn đăng k&yacute;. Sau khi x&aacute;c nhận, bạn sẽ nhận được m&atilde; OTP qua cuộc gọi từ GooPay về số điện thoại của bạn v&agrave; c&oacute; thể c&agrave;i đặt lại mật khẩu mới.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Phương thức 3: C&acirc;u hỏi bảo mật</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Nếu bạn đ&atilde; cung cấp th&ocirc;ng tin trong phần Trung t&acirc;m bảo mật tr&ecirc;n hệ thống của GooPay, bạn sẽ phải trả lời 1 c&acirc;u hỏi với đ&aacute;p &aacute;n đ&atilde; cung cấp (từ trước) khi y&ecirc;u cầu cấp lại mật khẩu. Sau khi c&acirc;u hỏi được x&aacute;c nhận ch&iacute;nh x&aacute;c, bạn sẽ nhận được m&atilde; OTP qua cuộc gọi từ GooPay về số điện thoại của bạn v&agrave; c&oacute; thể c&agrave;i đặt lại mật khẩu mới.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Phương thức 4: Bộ c&acirc;u hỏi x&aacute;c nhận danh t&iacute;nh của GooPay</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">T&ugrave;y thuộc v&agrave;o việc bạn c&oacute; đăng k&yacute; sử dụng t&iacute;nh năng đặng nhập nhanh ( fastlogin) hay kh&ocirc;ng, GooPay sẽ c&oacute; bộ c&acirc;u hỏi ph&ugrave; hợp với bạn để c&oacute; thể c&agrave;i đặt lại mật khẩu.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Đối với trường hợp bạn kh&ocirc;ng sử dụng đặng nhập nhanh (fastlogin), bạn sẽ cần trả lời c&aacute;c c&acirc;u hỏi của GooPay để x&aacute;c định danh t&iacute;nh, c&acirc;u hỏi c&oacute; thể số CCCD, ng&agrave;y cấp v&agrave; 4 số cuối CCCD, Ng&acirc;n h&agrave;ng li&ecirc;n kết, Giao dịch gần nhất.</p>\n' +
				     '    <p style="">Đối với trường hợp bạn c&oacute; đăng k&yacute; sử dụng fastlogin, GooPay sẽ cần bạn cung cấp một số th&ocirc;ng tin Chứng minh nh&acirc;n d&acirc;n gồm: ng&agrave;y cấp v&agrave; 4 số cuối thẻ li&ecirc;n kết.</p>\n' +
				     '    <p style="">Trường hợp 2: t&agrave;i khoản GooPay chưa li&ecirc;n kết với t&agrave;i khoản/ thẻ ng&acirc;n h&agrave;ng bất kỳ, hoặc số dư t&agrave;i khoản GooPay &lt; 200.000đ, khi bạn chọn qu&ecirc;n mật khẩu, GooPay sẽ gửi cho bạn 1 SMS OTP để điền v&agrave;o tr&ecirc;n app để c&oacute; thể c&agrave;i lại mật khẩu.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style=" text-align:center; line-height:115%; font-size:14pt; color: #338FE3"><strong>Kh&oacute;a, hủy, mở lại t&agrave;i khoản</strong></p>\n' +
				     '    <ol type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">T&ocirc;i muốn mở kh&oacute;a t&agrave;i khoản GooPay</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Trong trường hợp bạn muốn tạm kh&oacute;a t&agrave;i khoản GooPay, vui l&ograve;ng li&ecirc;n hệ CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">- Số t&agrave;i khoản GooPay</p>\n' +
				     '    <p style="">- Họ t&ecirc;n chủ t&agrave;i khoản</p>\n' +
				     '    <p style="">- Số CCCD</p>\n' +
				     '    <p style="">- Số dư t&agrave;i khoản GooPay</p>\n' +
				     '    <p style="">- T&agrave;i khoản ng&acirc;n h&agrave;ng đang li&ecirc;n kết (nếu c&oacute;)</p>\n' +
				     '    <p style="">- Email</p>\n' +
				     '    <p style="">- Địa chỉ</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Sau khi nhận được th&ocirc;ng tin, GooPay sẽ chủ động x&aacute;c minh v&agrave; hỗ trợ kh&oacute;a t&agrave;i khoản.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Lưu &yacute;: t&agrave;i khoản bị kh&oacute;a vẫn c&oacute; thể mở lại để sử dụng. Khi bạn c&oacute; nhu cầu mở kh&oacute;a, vui l&ograve;ng li&ecirc;n hệ trực tiếp với CSKH qua ch&iacute;nh số điện thoại đăng k&yacute; t&agrave;i khoản GooPay bị kh&oacute;a.</p>\n' +
				     '    <ol start="2" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">GooPay c&oacute; thể tự kh&oacute;a hoặc hủy t&agrave;i khoản của t&ocirc;i hay kh&ocirc;ng?</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">GooPay chỉ kh&oacute;a hoặc hủy t&agrave;i khoản của bạn trong 2 trường hợp sau:</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">1. Bạn y&ecirc;u cầu bộ phận CSKH của GooPay hỗ trợ kh&oacute;a hoặc hủy t&agrave;i khoản.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">2. GooPay nghi ngờ c&oacute; h&agrave;nh vi phạm ph&aacute;p được thực hiện qua t&agrave;i khoản, v&iacute; dụ như: lừa đảo, rửa tiền, v.v Việc kh&oacute;a t&agrave;i khoản trong trường hợp n&agrave;y nhằm đảm bảo an to&agrave;n cho chủ t&agrave;i khoản.</p>\n' +
				     '    <ol start="3" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">T&agrave;i khoản GooPay đ&atilde; hủy c&oacute; kh&ocirc;i phục được kh&ocirc;ng?</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Rất tiếc nếu như t&agrave;i khoản GooPay đ&atilde; được huỷ th&igrave; kh&ocirc;ng thể kh&ocirc;i phục lại được</p>\n' +
				     '    <ol start="4" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">L&agrave;m sao để kh&oacute;a t&agrave;i khoản GooPay? L&agrave;m sao để mở lại t&agrave;i khoản GooPay đ&atilde; kh&oacute;a?</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Trong trường hợp kh&ocirc;ng cần thiết phải hủy V&iacute; GooPay, bạn c&oacute; thể kh&oacute;a tạm thời t&agrave;i khoản GooPay của m&igrave;nh bằng c&aacute;ch li&ecirc;n hệ với bộ phận CSKH qua số 19006038 v&agrave; cung cấp c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n như trong phần &ldquo;hủy t&agrave;i khoản&rdquo; v&agrave; y&ecirc;u cầu GooPay kh&oacute;a tạm thời t&agrave;i khoản của bạn. Khi c&oacute; nhu cầu mở kho&aacute; t&agrave;i khoản, vui l&ograve;ng li&ecirc;n hệ trực tiếp với CSKH qua ch&iacute;nh số điện thoại đăng k&yacute; t&agrave;i khoản GooPay bị kh&oacute;a.</p>\n' +
				     '    <ol start="5" type="1" style="margin:0pt; padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '        <li style=" padding-left:3.83pt; font-weight:bold;">T&ocirc;i muốn hủy t&agrave;i khoản GooPay ?</li>\n' +
				     '    </ol>\n' +
				     '    <p style="">Trong trường hợp bạn muốn hủy t&agrave;i khoản GooPay, vui l&ograve;ng li&ecirc;n hệ trực tiếp đến CSKH của GooPay v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '    <p style="">- Họ t&ecirc;n chủ t&agrave;i khoản</p>\n' +
				     '    <p style="">- Số CCCD</p>\n' +
				     '    <p style="">- Số dư t&agrave;i khoản GooPay</p>\n' +
				     '    <p style="">- T&agrave;i khoản ng&acirc;n h&agrave;ng đang li&ecirc;n kết (nếu c&oacute;)</p>\n' +
				     '    <p style="">- Email</p>\n' +
				     '    <p style="">- Địa chỉ</p>\n' +
				     '    <p style="">Sau khi nhận được th&ocirc;ng tin từ bạn, GooPay sẽ chủ động x&aacute;c minh v&agrave; hỗ trợ hủy t&agrave;i khoản.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Lưu &yacute;:</p>\n' +
				     '    <p style="">Trước khi bạn gửi th&ocirc;ng tin y&ecirc;u cầu hủy t&agrave;i khoản, vui l&ograve;ng hủy li&ecirc;n kết giữa t&agrave;i khoản GooPay v&agrave; t&agrave;i khoản ng&acirc;n h&agrave;ng.</p>\n' +
				     '    <p style="">&nbsp;</p>\n' +
				     '    <p style="">Trong trường hợp t&agrave;i khoản GooPay của bạn vẫn c&ograve;n số dư, khi GooPay tiến h&agrave;nh hủy t&agrave;i khoản, số dư trong t&agrave;i khoản sẽ bị mất. T&agrave;i khoản GooPay đ&atilde; hủy sẽ kh&ocirc;ng thể kh&ocirc;i phục lại.</p>\n' +
				     '</div>\n' +
				     '<p style="bottom: 10px; right: 10px; position: absolute;"><a href="https://wordtohtml.net" target="_blank" style="font-size:11px; color: #d0d0d0;">Converted to HTML with WordToHTML.net</a></p>'
		     }}
		/>
	</div>
);

export default FAQAccount;
